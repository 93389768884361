<template>
  <div class="user">
    <h4>Parameter Push Record Detail</h4>
    <el-divider />
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #extraHandleBtns>
        <el-button
          icon="el-icon-back"
          @click="handleBackClick"
          size="small"
          plain
          >{{ $t('general.back') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'
// import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

const routes = useRoute()
const id = routes.params.id
const pageInitialParams = {
  flowId: id
}

const tableOptions = {
  moduleName: 'monitor', // 所属的模块
  pageName: 'monitorParamDetail', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  pageInitialQuery: pageInitialParams,
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  isShowEditBtn: false,
  isShowDeleteBtn: false,
  pageAction: 'monitor/getMonitorParamDetailList', // 获取表格的Action
  deleteRecordAction: 'monitor/handleDeleteTerminal', // 删除record的Action
  pageCountGetter: 'monitor/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'monitor/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const pageSearchRef = ref('')

// 发送网络请求
const pageContentRef = ref()
// const handleResetClick = () => {
//   pageContentRef.value.getPageData(pageInitialParams)
// }

// const handleQueryClick = (queryInfo) => {
//   pageContentRef.value.getPageData({
//     ...pageInitialParams,
//     ...queryInfo
//   })
// }
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/monitor/param/detail/${id}`)
}

const handleBackClick = () => {
  router.go(-1)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData({
    ...pageInitialParams,
    ...formData
  })
}
</script>
